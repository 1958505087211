export default function InputField(props: {
  id: string;
  name: string;
  inputClassName?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  required?: boolean;
  label?: string;
  placeholder?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  autoCorrect?: string;
  onFocus?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
}) {
  const {
    id,
    name,
    inputClassName,
    value,
    onChange,
    label,
    placeholder,
    type,
    required,
    autoFocus,
    autoComplete,
    autoCorrect,
    onFocus,
    onBlur,
    error,
  } = props;

  return (
    <div className={`flex flex-col bg-gray-900`}>
      <label htmlFor={id} className="flex flex-col gap-2">
        {label && (
          <div className="flex items-center justify-between">
            <span
              className={`
                font-body text-xs
                font-medium
                uppercase
                leading-none tracking-wider text-white
              `}
            >
              {label}
            </span>
          </div>
        )}

        <input
          className={`
            font-body
            font-book rounded-sm
            border-b border-solid
            px-4 py-3
            text-lg text-white focus:border-yellow-100 focus:outline-none
            ${inputClassName || ""}
            ${
              error
                ? "border-red-300 hover:border-red-400"
                : "border-gray-500 hover:border-yellow-100"
            }
          `}
          type={type || "text"}
          id={id}
          name={name}
          placeholder={placeholder || undefined}
          autoFocus={autoFocus || false}
          autoComplete={autoComplete || undefined}
          autoCorrect={autoCorrect || undefined}
          required={required || false}
          value={value}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onChange(event)
          }
          onFocus={
            onFocus
              ? (event: React.ChangeEvent<HTMLInputElement>) => onFocus(event)
              : undefined
          }
          onBlur={
            onBlur
              ? (event: React.ChangeEvent<HTMLInputElement>) => onBlur(event)
              : undefined
          }
        />
      </label>

      {error && (
        <div className="font-body font-book mt-1 text-base text-red-500">
          {error}
        </div>
      )}
    </div>
  );
}
