import {
  type ReactElement,
  type ButtonHTMLAttributes,
  type MouseEventHandler,
} from "react";
import * as React from "react";

export default function Button(props: {
  children: string | ReactElement;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  actionType?: string;
  disabled?: boolean;
  disabledText?: boolean;
}) {
  const {
    children,
    className,
    onClick,
    type,
    actionType,
    disabled,
    disabledText,
  } = props;

  let buttonBgClassName = "";
  let disabledClassName = "";

  switch (actionType) {
    case "destructive":
      buttonBgClassName = `
        text-white
        bg-gradient-to-b
        from-red-500 to-red-600 hover:from-red-600 hover:to-red-600
        shadow-lg shadow-red-600/25
      `;
      disabledClassName = `
        text-white
        bg-gray-400
        shadow-lg shadow-gray-400/25
      `;
      break;

    case "tertiary":
      buttonBgClassName = `
        text-gray-200 hover:text-gray-400
      `;
      disabledClassName = `
        text-gray-500
      `;
      break;

    case "secondary":
      buttonBgClassName = `
        text-gray-700
        bg-gradient-to-b
        from-white to-gray-50 hover:from-gray-50 hover:to-gray-50
        shadow-lg shadow-blue-500/25
      `;
      disabledClassName = `
        text-white
        bg-gray-400
        shadow-lg shadow-gray-400/25
      `;
      break;

    case "primary":
    case "submit":
    default:
      buttonBgClassName = `
        text-gray-900
        bg-yellow-100 hover:bg-yellow-400
      `;
      disabledClassName = `
        text-white
        bg-gray-400
        shadow-lg shadow-gray-400/25
      `;
      break;
  }

  return (
    <button
      type={type || "button"}
      className={`
        w-full rounded-sm
        px-6
        py-3 text-base font-semibold
        transition-colors duration-300
        ${className}
        ${disabled ? disabledClassName : buttonBgClassName}
      `}
      disabled={disabled || false}
      onClick={onClick || undefined}
    >
      {disabled && disabledText ? disabledText : children}
    </button>
  );
}
