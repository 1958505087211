import * as firebase from "firebase/app";
import {
  getAnalytics,
  isSupported as analyticsIsSupported,
} from "firebase/analytics";

const config = JSON.parse(
  atob(process.env.NEXT_PUBLIC_FIREBASE_CONFIG_JSON as string),
);

const app =
  firebase.getApps().length === 0
    ? firebase.initializeApp(config)
    : firebase.getApp();

const analytics = analyticsIsSupported().then((isSupported: boolean) =>
  isSupported ? getAnalytics(app) : null,
);

export { analytics };
