import { logEvent, setUserId } from "firebase/analytics";
import { analytics as analyticsPromise } from "@/lib/firebase";
import { isDebug } from "@/lib/env";

export enum AnalyticsScreenName {
  LogIn = "log_in",

  LogInWithApple = "log_in_with_apple",
  LogInWithGoogle = "log_in_with_google",
  LogOut = "log_out",
  ForgotPassword = "forgot_password",
  ResetPassword = "reset_password",
  Profile = "profile",
  Settings = "settings",

  Home = "home",
  Favorites = "favorites",

  Locations = "locations",
  LocationDetails = "location_details",

  Artists = "artists",
  ArtistDetails = "artist_details",

  RecordingDetails = "recording_details",

  RecordingDownload = "multitrack_download",
  RecordingCheckout = "multitrack_checkout",
  RecordingConfirmation = "multitrack_confirmation",
}

export const analytics = {
  setUserId: async (userId: string) => {
    const analyticsInstance = await analyticsPromise;

    if (!analyticsInstance) return;

    if (isDebug()) {
      console.debug("[Analytics] User ID: ", userId);
      return;
    }

    setUserId(analyticsInstance, userId);
  },

  logScreen: async (screenName: AnalyticsScreenName, screenClass?: string) => {
    const analyticsInstance = await analyticsPromise;
    if (!analyticsInstance) return;

    if (process.env.DEBUG || process.env.NEXT_PUBLIC_DEBUG) {
      console.debug("[Analytics] ScreenView: ", screenName, screenClass);
      return;
    }

    logEvent(analyticsInstance, "screen_view", {
      firebase_screen: screenName,
      firebase_screen_class: screenClass || screenName,
    });
  },

  logEvent: async (name: string, params?: { [key: string]: any }) => {
    const analyticsInstance = await analyticsPromise;

    if (!analyticsInstance) return;

    if (process.env.DEBUG || process.env.NEXT_PUBLIC_DEBUG) {
      console.debug("[Analytics] Event: ", name, params);
      return;
    }

    logEvent(analyticsInstance, name, params);
  },
};
