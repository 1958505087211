import { ReactElement, useEffect } from "react";
import { GetServerSideProps, GetServerSidePropsContext } from "next";
import { getServerSession } from "next-auth";

import { AnalyticsScreenName, analytics } from "@/lib/analytics";
import { authOptions } from "@/lib/next-auth";

import { NextPageWithLayout } from "@/pages/_app";
import AuthLayout from "@/components/layouts/AuthLayout";

import AppleLoginButton from "@/components/auth/AppleLoginButton";
import GoogleLoginButton from "@/components/auth/GoogleLoginButton";
import EmailInputForm from "@/components/auth/EmailInputForm";
import Logo from "@/components/images/Logo";

const LogInPage: NextPageWithLayout = () => {
  useEffect(() => {
    analytics.logScreen(AnalyticsScreenName.LogIn, "LogInPage");
  }, []);

  return (
    <div
      className={`
        flex
        w-full flex-col gap-8
        rounded-sm
        bg-gray-800
        p-8 pt-6
      `}
    >
      <Logo
        bgColor="#ffd500"
        colorClass="text-black"
        heightClass="h-10"
        widthClass=""
      />

      <div className="flex flex-col items-center gap-8">
        <div className="flex w-full flex-col items-center gap-1">
          <div className="text-center text-2xl font-semibold text-gray-100">
            Log In to Setmixer
          </div>
          <div className="text-center text-base text-gray-400">
            Your home for live recordings
          </div>
        </div>

        <div className="flex w-full flex-col gap-8">
          <EmailInputForm />

          <div
            className={`
            flex h-px w-full
            items-center justify-center bg-gray-700
          `}
          >
            <div className="text-2xs bg-gray-800 px-4 font-semibold uppercase tracking-widest text-gray-400">
              or continue with
            </div>
          </div>

          <div className="grid grid-flow-col items-center justify-stretch gap-4">
            <GoogleLoginButton />
            <AppleLoginButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async (
  context: GetServerSidePropsContext,
) => {
  const session = await getServerSession(context.req, context.res, authOptions);

  if (session?.user) {
    return {
      redirect: {
        destination: "/home",
        permanent: false,
      },
    };
  }

  return { props: {} };
};

LogInPage.getLayout = (page: ReactElement) => {
  return <AuthLayout>{page}</AuthLayout>;
};

export default LogInPage;
