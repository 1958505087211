import { useEffect } from "react";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

export default function AuthLayout(props: any) {
  const { children } = props;
  const router = useRouter();
  const { status, data: session } = useSession();
  const isLoading = status === "loading";
  const isAuthenticated = status === "authenticated";

  useEffect(() => {
    if (isAuthenticated && session?.user) {
      router.replace("/favourites/");
    }
  }, [router, session, isAuthenticated]);

  return (
    <main className="flex h-screen w-full flex-col items-center justify-center p-4">
      <div
        style={{
          backgroundImage: process.env.NEXT_PUBLIC_SM_BG_IMAGE_URL
            ? `url('${process.env.NEXT_PUBLIC_SM_BG_IMAGE_URL}')`
            : undefined,
        }}
        className={`
          fixed inset-0
          -z-[1] bg-gray-900 bg-cover
          bg-center
          grayscale
        `}
      />

      <div className="flex min-w-80 flex-col items-center justify-center sm:min-w-96">
        {isLoading ? (
          <div
            className={`
              absolute
              inset-0

              flex items-center justify-center
              bg-gray-900
              p-6
              text-white
            `}
          >
            <div>Loading...</div>
          </div>
        ) : (
          children
        )}
      </div>
    </main>
  );
}
