import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { AUTH_URL, STAGE, WEBAPP_URL } from "@/lib/env";
import { z } from "zod";
import { Button } from "@/components/buttons";
import { InputField } from "@/components/form";
import { useRouter } from "next/router";
import { useEffect } from "react";

export default function EmailInputForm() {
  const router = useRouter();
  const { prefillEmail, callbackUrl } = router.query;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<{ email: string }>({
    resolver: async (data, context, options) => {
      return zodResolver(
        z.object({
          email: z.string().email(),
        }),
      )(data, context, options);
    },
    defaultValues: {
      email: typeof prefillEmail === "string" ? prefillEmail : "",
    },
  });

  useEffect(() => {
    if (typeof prefillEmail === "string") {
      setValue("email", prefillEmail);
    }
  }, [prefillEmail, setValue]);

  const onSubmit: SubmitHandler<{ email: string }> = (data) => {
    const emailAuthParams = new URLSearchParams({
      client_id: `webapp-${STAGE}`,
      provider: "email",
      email: data.email,
      response_type: "code",
      redirect_uri: `${WEBAPP_URL}/api/auth/sst/callback/`,
    });

    if (callbackUrl) {
      emailAuthParams.set("state", callbackUrl as string);
    }

    location.href = AUTH_URL + "/authorize?" + emailAuthParams.toString();
  };

  return (
    <form className="flex flex-col gap-2" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-1">
        <span className="text-xs text-red-500">{errors.email?.message}</span>

        <InputField
          required
          id="email"
          label="email"
          placeholder="Enter your email address"
          register={register}
          className={`${errors.email?.message ? "border border-red-500" : ""}`}
        />
      </div>

      <Button type="submit">Continue with Email</Button>
    </form>
  );
}
