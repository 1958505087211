import { getSession, signIn, signOut } from "next-auth/react";
import {
  STAGE,
  WEBAPP_URL,
  NEXT_PUBLIC_INSTAGRAM_CLIENT_ID,
  NEXT_PUBLIC_INSTAGRAM_REDIRECT_URI,
} from "@/lib/env";

export const logIn = async (accessToken: string) => {
  await signIn("credentials", {
    accessToken,
    callbackUrl: "/favourites",
    redirect: true,
  });
};

interface LogInOptions {
  redirectAfterLogIn: string;
}

export const logInWithApple = ({ redirectAfterLogIn }: LogInOptions) => {
  const params = new URLSearchParams({
    client_id: `webapp-${STAGE}`,
    redirect_uri: `${WEBAPP_URL}/api/auth/sst/callback/`,
    response_type: "code",
    provider: "apple",
  });

  if (redirectAfterLogIn && redirectAfterLogIn.indexOf("/") === 0) {
    params.set("state", redirectAfterLogIn);
  }

  location.href =
    process.env.NEXT_PUBLIC_AUTH_URL + "/authorize?" + params.toString();
};

export const logInWithGoogle = ({ redirectAfterLogIn }: LogInOptions) => {
  const params = new URLSearchParams({
    client_id: `webapp-${STAGE}`,
    redirect_uri: `${WEBAPP_URL}/api/auth/sst/callback/`,
    response_type: "code",
    provider: "google",
  });

  if (redirectAfterLogIn && redirectAfterLogIn.indexOf("/") === 0) {
    params.set("state", redirectAfterLogIn);
  }

  location.href =
    process.env.NEXT_PUBLIC_AUTH_URL + "/authorize?" + params.toString();
};

export const logOut = async () => {
  await signOut({ redirect: true, callbackUrl: "/" });
};

type InstagramAuthState = {
  setmixerAccessToken: string;
  redirectPath: string;
};

export const getInstagramAuthState = (state: string) => {
  return JSON.parse(atob(state)) as InstagramAuthState;
};

export const createInstagramAuthState = (
  userId: string,
  redirectPath: string,
) => {
  return btoa(
    JSON.stringify({
      userId,
      redirectPath,
    }),
  );
};

export const authWithInstagram = async (
  redirectPath: string = "",
  callback: (error?: string) => void,
) => {
  if (!window || !window.open || !screen) {
    callback(
      "This browser does not support popups. Please try again in a different browser.",
    );
    return;
  }

  const session = await getSession();
  const url = "https://api.instagram.com/oauth/authorize";
  const state = createInstagramAuthState(session?.user.id || "", redirectPath);

  const params = new URLSearchParams({
    client_id: NEXT_PUBLIC_INSTAGRAM_CLIENT_ID,
    redirect_uri: NEXT_PUBLIC_INSTAGRAM_REDIRECT_URI,
    scope: "user_profile",
    response_type: "code",
    state,
    force_authentication: "true",
  });

  window.open(url + "?" + params.toString(), "_self");
};

export const deauthWithInstagram = (callback: () => void) => {
  // TODO: Implement this properly
  console.log("NYI");
};
