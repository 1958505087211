import { UseFormRegister } from "react-hook-form";

export default function InputField({
  id,
  label,
  placeholder,
  required,
  register,
  className,
}: {
  id?: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  register: UseFormRegister<any>;
  className?: string;
}) {
  return (
    <input
      className={`
        rounded-sm bg-gray-700
        px-4
        py-3
        text-base text-white
        ${className || ""}
      `}
      id={id}
      placeholder={placeholder}
      {...register(label, { required })}
    />
  );
}
