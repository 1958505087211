import { Google } from "@/components/icons";
import { AnalyticsScreenName, analytics } from "@/lib/analytics";
import { logInWithGoogle } from "@/lib/auth";
import { useRouter } from "next/router";

export default function GoogleLoginButton() {
  const router = useRouter();

  return (
    <button
      type="button"
      className={`
        flex min-h-12 items-center justify-center
        gap-3 rounded-sm bg-yellow-100
        px-4
        py-3
        text-base
        font-semibold
        text-gray-900 duration-300
        hover:bg-yellow-400
      `}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        analytics.logEvent(AnalyticsScreenName.LogInWithGoogle, {});

        logInWithGoogle({
          redirectAfterLogIn: router.query.callbackUrl as string,
        });
      }}
    >
      <Google widthClass="w-5" heightClass="h-5" colorClass="text-gray-900" />

      <div>Google</div>
    </button>
  );
}
